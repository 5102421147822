<template>
  <div class="serveFlow">
    <div class="labour_four">
      <div class="all_box">
        <div class="labour_four_title">
          <h3>服务流程与保障</h3>
          <p>标准化服务流程保障社保无忧</p>
        </div>

        <div class="labour_four_list">
          <div class="labour_for_item one">
            <img :src="dataList[0].img" />
            <p>{{dataList[0].title}}</p>
          </div>
          <div class="labour_for_item two">
            <img src="@/assets/bz_gd.png" />
          </div>
          <div class="labour_for_item one">
            <img :src="dataList[1].img" />
            <p>{{dataList[1].title}}</p>
          </div>
          <div class="labour_for_item two">
            <img src="@/assets/bz_gd.png" />
          </div>
          <div class="labour_for_item one">
            <img :src="dataList[2].img" />
            <p>{{dataList[2].title}}</p>
          </div>
          <div class="labour_for_item two">
            <img src="@/assets/bz_gd.png" />
          </div>
          <div class="labour_for_item one">
            <img :src="dataList[3].img" />
            <p>{{dataList[3].title}}</p>
          </div>
          <div class="labour_for_item two">
            <img src="@/assets/bz_gd.png" />
          </div>
          <div class="labour_for_item one">
            <img :src="dataList[4].img" />
            <p>{{dataList[4].title}}</p>
          </div>
          <div class="labour_for_item two">
            <img src="@/assets/bz_gd.png" />
          </div>
          <div class="labour_for_item one">
            <img :src="dataList[5].img" />
            <p>{{dataList[5].title}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Array,
    }
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.labour_for_item.one {
  font-size: 16px;
  color: #656565;
  width: 12%;
}
.labour_for_item.two {
  img {
    margin-top: 50px;
  }
}
.labour_four_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 100px;
}
.labour_four_title {
  h3 {
    margin: 0;
    font-size: 36px;
    color: #333333;
  }
  p {
    margin: 0;
    font-size: 16px;
    color: #969696;
    margin-top: 28px;
  }
}
.labour_four {
  padding: 100px 0 130px;
}
</style>