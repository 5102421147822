<template>
  <div class="text_title">
    <h3>{{ enTitle }}</h3>
    <h4>{{ cnTitle }}</h4>
  </div>
</template>
<script>
export default {
  props: {
    cnTitle: {
      type: String,
    },
    enTitle: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.text_title {
  position: relative;
  height: 80px;
  h3 {
    font-size: 60px;
    color: #2b343f;
    opacity: 0.08;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
  }
  h4 {
    font-size: 44px;
    color: #333333;
    font-weight: bold;
    margin: 0;
    position: absolute;
    width: 1300px;
    text-align: center;
    top: 22px;
  }
}
</style>