<template>
  <div class="labour">
    <titles></titles>
    <div class="banner">
      <div class="banner_text">
        <div class="banner_text_box">
          <h3>{{ $config.bmYears }}年人力资源服务供应商 </h3>
          <p>企业劳务派遣用工 开启低风险用工新方式</p>
          <p>降本增效 共创共赢</p>
          <div class="banner_btn"><a href="https://affim.baidu.com/unique_28438292/chat?siteId=13950180&userId=28438292&siteToken=22dd0d31e1f7a80559410d71206df7db" target="_blank">立即咨询</a></div>
        </div>
      </div>
      <img src="@/assets/labour_banner.jpg" />
    </div>

    <div class="labour_one">
      <div class="all_box">
        <div class="labour_title">
          <textTitle
            :cnTitle="txt.oneCnTitle"
            :enTitle="txt.oneEnTitle"
          ></textTitle>
        </div>
        <div class="labour_one_box">
          <div class="labour_one_img">
            <img src="@/assets/labour_one.png " />
          </div>
          <div class="labour_one_list">
            <div class="labour_one_item">
              <img src="@/assets/yuan.png" />
              完全派遣
            </div>
            <div class="labour_one_item">
              <img src="@/assets/yuan.png" />
              减员派遣
            </div>
            <div class="labour_one_item">
              <img src="@/assets/yuan.png" />
              试用派遣
            </div>
            <div class="labour_one_item">
              <img src="@/assets/yuan.png" />
              短期派遣
            </div>
            <div class="labour_one_item">
              <img src="@/assets/yuan.png" />
              项目派遣
            </div>
          </div>
        </div>
      </div>
      <div class="henfu_po">
        <henfu></henfu>
      </div>
    </div>

    <div class="labour_two">
      <div class="all_box">
        <div class="labour_title">
          <textTitle
            :cnTitle="txt.twoCnTitle"
            :enTitle="txt.twoEnTitle"
          ></textTitle>
        </div>
        <div class="labour_two_list">
          <div class="labour_two_item">
            <div class="labour_two_pic">
              <img src="@/assets/lw01.png" />
            </div>
            <h3>减少事务工作</h3>
            <div class="henxian"></div>
            <div class="labour_two_text">
              <p>企业可大幅减少人事事务性工作，集中提升核心竞争力</p>
            </div>
            <div class="number">01</div>
          </div>
          <div class="labour_two_item">
            <div class="labour_two_pic">
              <img src="@/assets/lw02.png" />
            </div>
            <h3>降低用工成本</h3>
            <div class="henxian"></div>
            <div class="labour_two_text">
              <p>企业可降低招聘、培训、管理等各类用工成本</p>
            </div>
            <div class="number">02</div>
          </div>
          <div class="labour_two_item">
            <div class="labour_two_pic">
              <img src="@/assets/lw03.png" />
            </div>
            <h3>灵活用退风险低</h3>
            <div class="henxian"></div>
            <div class="labour_two_text">
              <p>企业拥有较方便的人员用退流程，其他事务由邦芒解决</p>
            </div>
            <div class="number">03</div>
          </div>
          <div class="labour_two_item">
            <div class="labour_two_pic">
              <img src="@/assets/lw04.png" />
            </div>
            <h3>社保属地化</h3>
            <div class="henxian"></div>
            <div class="labour_two_text">
              <p>企业利用邦芒全国服务网点资源将员工属地化社保，降低投保成本</p>
            </div>
            <div class="number">04</div>
          </div>
          <div class="labour_two_item">
            <div class="labour_two_pic">
              <img src="@/assets/lw05.png" />
            </div>
            <h3>减少劳动争议</h3>
            <div class="henxian"></div>
            <div class="labour_two_text">
              <p>
                企业将劳动关系转移，产生的劳动纠纷由邦芒处理解决，从根本上把控用工风险
              </p>
            </div>
            <div class="number">05</div>
          </div>
          <div class="labour_two_item">
            <div class="labour_two_pic">
              <img src="@/assets/lw06.png" />
            </div>
            <h3>解决编制不足</h3>
            <div class="henxian"></div>
            <div class="labour_two_text">
              <p>
                企业不用担心淡旺季，临时性、辅佐性、替代性等三性岗位而产生的临时用工
              </p>
            </div>
            <div class="number">06</div>
          </div>
        </div>
      </div>
    </div>

    <IndustrySolutions :type="3"></IndustrySolutions>

    <serveFlow :dataList="serveList"></serveFlow>
    <check :type="2" @gotoContact="gotoContact($event)"></check>

    <foots @navChange = "navChange"></foots>
  </div>
</template>
<script>
//图片
import bz01 from "@/assets/bz01.png";
import bz02 from "@/assets/bz02.png";
import bz03 from "@/assets/bz03.png";
import bz04 from "@/assets/bz04.png";
import bz05 from "@/assets/bz05.png";
import bz06 from "@/assets/bz06.png";

import IndustrySolutions from "@/components/IndustrySolutions.vue";
import serveFlow from "@/components/serveFlow.vue";
import henfu from "@/components/henfu.vue";
import check from "@/components/check.vue";
import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";
import textTitle from "@/components/textTitle.vue";
export default {
  name: "Labour",
  components: {
    IndustrySolutions,
    titles,
    foots,
    textTitle,
    check,
    henfu,
    serveFlow,
  },
  data() {
    return {
      serveList: [
        {
          title: "提供服务方案",
          img: bz01,
        },
        {
          title: "签订服务协议",
          img: bz02,
        },
        {
          title: "选择派遣人才",
          img: bz03,
        },
        {
          title: "派遣人才为企业服务",
          img: bz04,
        },
        {
          title: "用工过程支持",
          img: bz05,
        },
        {
          title: "人员更替",
          img: bz06,
        },
      ],
      txt: {
        oneCnTitle: "劳务派遣服务方案",
        oneEnTitle: "Service plan",
        twoCnTitle: "劳务派遣的六大优势",
        twoEnTitle: "Six advantages",
      },
    };
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
    //跳转到contact 并查询
    gotoContact(e) {
      this.$router.push({
        path: "/contact",
        query: {
          sCode: e.sCode,
          qCode: e.qCode,
          compname: e.compname,
        },
      });
    },
    navChange(){
      window.scrollTo(0, 0);
    }
  },
};
</script>
<style lang="scss" scoped>
.banner_btn {
  a{
    color: #fff;
    display: block;
    text-decoration: none;
  }
  font-size: 16px;
  width: 140px;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  background: #d70035;
  line-height: 40px;
  margin-top: 90px;
}
.banner {
  position: relative;
  .banner_text {
    position: absolute;
    width: 100%;
    text-align: left;
    top: 50%;
    margin-top: -100px;
  }
  .banner_text_box {
    width: 80%;
    margin: 0 auto;
    h3 {
      font-size: 55px;
      color: #41765d;
      margin: 0;
      margin-bottom: 32px;
    }
    p {
      font-size: 24px;
      color: #696f6c;
      margin: 0;
      margin: 12px 0;
      span {
        color: #d70035;
      }
    }
  }
}
.labour_two {
  padding-bottom: 200px !important;
}
.labour_two_pic {
  margin-top: -45px;
}
.labour_one {
  position: relative;
  padding-bottom: 175px;
}
.labour_one_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  margin-top: 60px;
  .labour_one_item {
    font-size: 24px;
    color: #696969;
    font-weight: bold;
  }
}
.labour_one_box {
  background: #fff;
  padding: 60px 0 55px;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}
.labour_title {
  padding: 95px 0;
}
.labour {
  background: #f2f2f2;
}
.labour_two_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  // .labour_two_item:nth-child(odd){

  // }

  .labour_two_item:nth-child(even) {
    margin-top: 90px;
  }

  .labour_two_item {
    position: relative;
    background: url(../../assets/lw_item_bg.png) center center no-repeat;
    background-size: 100% 307px;
    width: 15%;
    height: 307px;
    h3 {
      font-size: 22px;
      color: #fff;
    }
    .henxian {
      height: 2px;
      width: 122px;
      background: #fff;
      margin: 0 auto;
    }
    .labour_two_text {
      padding: 0 8px;
      box-sizing: border-box;
      font-size: 14px;
      color: #ffffff;
      text-align: left;
      margin-bottom: 15px;
      margin-top: 12px;
      p {
        margin: 0;
      }
    }
    .number {
      position: absolute;
      width: 100%;
      text-align: center;
      font-size: 42px;
      color: #fff;
      opacity: 0.3;
      font-weight: bold;
      bottom: 30px;
    }
  }
}

.henfu_po {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>