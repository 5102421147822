<template>
  <div class="henfu">
    <div class="labour_henfu">
      <div class="all_box">
        <div class="labour_henfu_box">
          <div class="labour_henfu_item">
            <h3>{{ $config.bmYears }}<span>年</span></h3>
            <p>{{ $config.bmYears }}年实体直营</p>
          </div>
          <div class="labour_henfu_item">
            <h3>228<span>+</span></h3>
            <p>全国228+分公司</p>
          </div>
          <div class="labour_henfu_item">
            <h3>400<span>+</span></h3>
            <p>400+城市社保服务网点</p>
          </div>
          <div class="labour_henfu_item">
            <h3>30000<span>+</span></h3>
            <p>30000+企业信赖选择</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.labour_henfu {
//   background: url(../../assets/henfu_bg.jpg) center center no-repeat;
  background: url(../../src/assets/henfu_bg.jpg) center center no-repeat;
  background-size: 100% 100%;
  width: 100%;
  z-index: 0;
}
.labour_henfu_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 120px 0 60px;
  .labour_henfu_item {
    color: #fff;
    h3 {
      font-size: 50px;
      margin: 0;
      span {
        font-size: 24px;
      }
    }
    p {
      margin: 0;
      font-size: 22px;
    }
  }
}

</style>